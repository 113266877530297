$Lato: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
$red: #ff664d;
$black: #373a3c;
$grey: rgb(144, 144, 144);
$darkgray: rgb(128, 128, 128);
$white: #fff;
$orange: #ff664d;
$green: #5fd2cb;
$primary: $orange;
$secondary: $green;
$break-desktop: 900px;
$break-tablet: 768px;
$break-tablet-down: 767px;
$transition: 0.2s ease-in-out;
