@import "./css/variables.scss";
body,
html {
  height: auto !important;
  overscroll-behavior: none;
}
.input-title {
  color: #373a3c;
  font-family: $Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

@import "./css/main.scss";
