@import './variables.scss';
@import './imports.scss';

html {
  scroll-behavior: smooth;
}
button {
  cursor: pointer;
}
.root-container {
  // min-height: 70vh;
  padding: 0;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;
  .footer-flex-container {
    flex: 1;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  @media screen and (min-width: $break-tablet) {
    margin-top: 75px;
  }
}
.site-container {
  @media screen and (min-width: $break-tablet) {
    max-width: 1012px;
    width: 90%;
    // margin-left: 10%;
    margin: 0 auto;
    // margin-right: 10%;
  }
}
.roomigo-container {
  width: 100%;
  // max-width: 398px;
  margin: auto;
}
.p-relative {
  position: relative;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}
.flex-1 {
  flex: 1;
}
.mr-6 {
  margin-right: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.my-auto {
  margin: auto 0 !important;
}
.mx-auto {
  margin: 0 auto !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.link {
  cursor: pointer;
  color: $orange;
  transition: color $transition;
  &:hover,
  &:focus {
    color: darken(desaturate($orange, 10%), 10%);
  }
  &:active {
    color: lighten(desaturate($orange, 10%), 10%);
  }
  &--light {
    color: $white;
    &:hover,
    &:focus {
      color: darken($white, 10%);
    }
    &:active {
      color: darken($white, 5%);
    }
  }
  &--dark {
    color: $darkgray;
    &:hover,
    &:focus {
      color: darken($darkgray, 50%);
    }
    &:active {
      color: lighten($darkgray, 10%);
    }
  }
  &--decorated {
    &,
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
.title-16 {
  display: block;
  color: $black;
  font-family: $Lato;
  font-size: 16px;
  font-weight: bold;
  margin: 33px 0px 10px;
  &.light {
    font-weight: 400;
  }
  .optional {
    margin-left: 10px;
    color: #999999;
    font-family: $Lato;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}

.room-listing-date-input {
  max-width: 107px !important;
  min-width: 90px;
  input {
    color: #999999 !important;
    text-align: center !important;
    font-family: $Lato !important;
    font-size: 14px !important;
  }
}

.autocomplete-dropdown-container {
  border: 1px solid #ccc;
  opacity: 1;
  transition: 0.3s opacity;
  position: absolute;
  z-index: 10;
  background: #ffff;
  width: 100%;
  &.hidden-autocomplete-dropdown {
    opacity: 0;
  }
  .google-suggestion-item {
    display: block;
    font-size: 16px;
    font-family: $Lato;
    color: #999999;
    &.suggestion-item--active {
      background: #ebebeb;
    }
    span {
      border-bottom: 1px solid #ccc;
      padding: 10px 5px;
      display: block;
      margin: 0px 15px;
    }
    &:last-child {
      span {
        border-bottom: none;
      }
    }
  }
}
.room-listing-map {
  max-width: 100%;
  height: auto;
  margin-top: 0px;
}
.error-13 {
  display: block;
  margin: 8px 0;
  opacity: 0.8;
  color: #ff1212;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  visibility: hidden;
  transition: 0.3s opacity;
  min-height: 19px;
  &.visible {
    opacity: 0.8;
    visibility: visible;
  }
}
.roomigo-submit-btn {
  outline: none !important;
  font-weight: 400;
  padding: 11px;
  width: 100%;
  border-radius: 25px;
  background-color: #ff674d;
  border: 1px solid transparent;
  color: #ffffff;
  font-family: $Lato;
  font-size: 17px;
  text-align: center;
  &.next-btn {
    display: block;
    width: 200px;
    margin-top: 25px;
    margin: auto;
    background-color: $green;
    @media screen and (min-width: $break-tablet) {
      margin: 25px 0 0;
    }
  }
}
.room-list-section {
  opacity: 1;
  visibility: visible;
  max-height: auto;
  transition: 0.3s;
  &.invisible-section {
    max-height: 0;
    opacity: 0;
    display: none;
  }
}
#searchAddress {
  margin-bottom: 33px;
}
.search-address-note {
  opacity: 0.5;
  font-size: 16px;
  margin: 10px 0;
  display: block;
}
.ui.button.roomigo-btn {
  border: 1px solid #cccccc !important;
  color: $black !important;
  background: transparent;
  font-weight: 400 !important;
  font-family: $Lato;
  font-size: 14px;
  padding: 11px 5px;
  line-height: 18px;
  &.active {
    background-color: #ff674d !important;
    border: 1px solid #ff674d !important;
    color: #fff !important;
  }
  @media screen and (min-width: $break-desktop) {
    padding: 11px 8px;
  }
}
.remove-to-date-btn {
  position: absolute;
  font-family: 'Lato', sans-serif;
  bottom: -24px;
  font-size: 12px;
  right: 0;
  color: #999999;
}

.loading-container {
  padding: 50px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  &.image-upload-loader {
    padding: 0;
  }
  .loading-text {
    margin: auto;
    font-family: $Lato;
    font-size: 16px;
    font-weight: 400;
    padding-top: 20px;
    color: $black;
  }
  .loading-spinner {
    margin: auto;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .loading-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $black;
    border-radius: 50%;
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $black transparent transparent transparent;
  }
  .loading-spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loading-spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loading-spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.upload-image-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 186px;
  width: 100%;
  border: 1px dashed $orange;
  &.upload-icon-background {
    background: url('./assets/images/upload_image.png') no-repeat;
    background-position: center;
  }
  .add-new-room-image-btn {
    position: relative;
    width: 114px;
    height: 82px;
    border: 1px dashed $orange;
    margin: 5px;
    display: flex;
    .roomigo-plus-icon {
      margin: auto;
      width: 30px;
      height: 30px;
      display: block;
      position: relative;
      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        background: $orange;
        position: absolute;
        top: 14px;
      }
      &:before {
        transform: rotate(0deg);
      }
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}

.roomigo-room-list-image-container {
  position: relative;
  // width: 114px;
  height: 92px;
  .roomigo-room-list-image-inner-container {
    padding: 5px;
    height: 100%;
    .roomigo-delete-room-list-image-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
      opacity: 0.8;
      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        background: #fff;
        position: absolute;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        top: 6px;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(135deg);
      }
    }
    .roomigo-room-list-image {
      width: auto;
      height: auto;
      max-height: 100%;
    }
  }
}
.room-list-flex-property {
  min-width: 300px;
  margin: 0px 10px;
  //   border: 1px solid red;
  padding: 0px 10px;
  &.min-w-45 {
    min-width: 45%;
  }
  &.roomigo-row {
    @media screen and (min-width: $break-tablet) {
      display: flex;
      padding: 10px 0;
      max-width: 493px;
      margin: 0;
      .flex-row {
        flex: 1;
      }
      & > .title-16.light {
        margin: auto 0;
        min-width: 114px;
      }
    }
  }
  &--desktop-half {
    @media screen and (min-width: $break-desktop) {
      min-width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
}
.location-listing-row {
  margin-top: 33px;
}
.preference-description {
  color: rgb(112, 112, 112);
  margin-top: 10px;
}

.desktop-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: $break-desktop) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.main-page-banner {
  position: relative;
  height: 320px;
  opacity: 1;

  @media screen and (min-width: $break-tablet) {
    height: calc(100vh - 280px);
    min-height: 300px;
  }

  .background-image {
    background: url('./assets/images/header.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
  }

  .dark-banner-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.09);
    z-index: 0;
  }
  .banner-text-container {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;

    @media screen and (min-width: $break-tablet) {
      right: -19%;
    }

    .banner-text-container-inner {
      margin: auto;
      color: #fff;
      font-family: $Lato;
      font-size: 14px;
      text-shadow: 0 1px 3px rgba(34, 36, 38, 0.5);

      @media screen and (min-width: $break-tablet) {
        font-size: 20px;
      }
      .banner-nav-buttons {
        margin: 19px 0;
        .banner-nav-btn {
          border-radius: 16.5px;
          background-color: #ff674d;
          border: 1px solid transparent;
          color: #fff;
          font-weight: 300;
          padding: 8px 20px;
          margin-right: 15px;
          outline: none;
          cursor: pointer;
          &.green {
            background-color: $green;
          }
        }
      }
      .banner-not-in-city {
        outline: none;
        background: transparent;
        border: none;
        color: #fff;
        font-family: $Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        @media screen and (min-width: $break-tablet) {
          font-size: 15px;
        }
      }

      .main-title {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        &.light {
          font-weight: 300;
        }
        @media screen and (min-width: $break-tablet) {
          font-size: 40px;
        }
      }
      .city-select {
        margin-top: 85px;
        .city-buttons-container {
          .city-btn {
            border: 1px solid transparent;
            color: #fff;
            font-weight: 400;
            border-radius: 5px;
            background-color: $orange;
            padding: 10px;
            width: 125px;
            margin-right: 15px;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sidebar-overlay {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0;
  z-index: 101;
  pointer-events: none;
  transition: opacity $transition;
  &--visible {
    opacity: 0.1;
    pointer-events: all;
  }
}

.sidebar-button {
  align-items: flex-start;
  display: flex;

  .button-text {
    color: #666;
    font-family: MYRIAD;
    font-size: 16px;

    .button-icon {
      margin-right: 15px;
    }
  }
}

.mobile-navbar-button {
  background-color: $green !important;
  color: $white !important;
  font-size: 15px !important;
  margin-left: -50px !important;
}

.navbar-button {
  background-color: $orange !important;
  color: $white !important;
  font-size: 15px !important;
}

.request-button {
  margin-bottom: 15px;
}

.bottom-buttons {
  @media screen and (min-width: $break-tablet) {
    width: 300px;
  }
}

.share-button-div {
  margin-top: 30px;
}

.share-button {
  background-color: $orange !important;
  color: $white !important;
}

.share-label {
  margin-bottom: 10px !important;
  //background-color: $orange !important;
  //color: $white !important;
}

.social-grid > * {
  cursor: pointer;
}

.ui.form {
  padding-top: 1rem;
}

.register-wrapper {
  padding-top: 1rem;
  min-height: 270px;
}

.login-wrapper {
  padding-top: 2rem;
  min-height: 280px;
}

.reset-password-wrapper {
  padding-top: 1rem;
  min-height: 210px;
}

.email-login-wrapper {
  padding-top: 1rem;
  min-height: 140px;
}

.register-wrapper,
.login-wrapper,
.email-login-wrapper,
.reset-password-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  @media screen and (max-width: $break-tablet-down) {
    align-items: flex-start;
    height: auto;
    min-height: initial;
  }
}

.green-text {
  color: $green !important;
}

.green-bg {
  background-color: $green !important;
}

.orange-text {
  color: $orange !important;
}

.orange-bg {
  background-color: $orange !important;
}

.grey-text {
  color: $grey !important;
}

.grey-bg {
  background-color: $grey !important;
}

.white-text {
  color: $white !important;
}

.mobile-sidebar-profile-avatar {
  margin-right: 12px !important;
}

.floating-cta {
  position: sticky !important;
  bottom: 29px;
  z-index: 2;
  margin-right: 80px;

  .cta-btn {
    display: block !important;
    width: 200px;
    margin: auto !important;
    color: $white !important;
    background-color: $primary !important;
    border-radius: 25px !important;
    @media screen and (min-width: $break-tablet) {
      display: none !important;
    }
  }
}

.home-bottom {
  padding-top: 25px !important;
  display: flex !important;
  flex-direction: column-reverse !important;
}

.seenin {
  margin-bottom: 50px !important;
  @media screen and (min-width: $break-tablet) {
    padding-top: 25px !important;
  }
  @media screen and (max-width: $break-tablet) {
    padding-bottom: 45px !important;
  }
  > * {
    margin: auto !important;
  }
  .image {
    padding-top: 15px !important;
    @media screen and (max-width: $break-tablet) {
      padding-top: 27px !important;
    }
    width: 250px !important;
    img {
      margin: auto;
    }
  }
}
