.chat-request-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  margin-bottom: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  &__tip {
    display: flex;
    align-items: center;

    svg {
      display: block;
      width: 25px;
      margin-right: 5px;
    }
  }
}
