.default-modal {
  width: 500px;
  max-width: 95%;
  padding: 25px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;

  &__close {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 50%;
    margin: 5px;
    padding: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      display: block;
      width: 100%;
    }

    &:hover,
    &:focus {
      outline: none;
      background: $red;

      svg {
        stroke: #fff;
      }
    }
  }
}
