@font-face {
    font-family: 'ODIN';
    src: url('MYRIADPRO-REGULAR.OTF');
}

@font-face {
    font-family: 'MYRIAD';
    src: url('MYRIADPRO-REGULAR.OTF');
}

@font-face {
    font-family: 'MYRIAD-BOLD';
    src: url('MYRIADPRO-BOLD.OTF');
}