.choose-room {
  &__slider {
    .slick-dots {
      position: static;
      bottom: auto;
    }

    .slick-dots li {
      margin: 0;
    }
  }

  &__header {
    margin: 0 0 15px 0;
  }

  &__container {
    display: flex;
    height: 180px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }

  &__image {
    width: 60%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__info {
    width: 40%;
    background: #000;
    padding: 25px;
    color: #fff;
    font-family: $Lato;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin: 0;
    margin-bottom: 8px;
    font-size: 16px;
  }

  &__desc {
    font-size: 14px;

    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }

  &__actions {
    margin-top: 20px;
    text-align: center;

    > * {
      display: block;
      width: 100%;
    }
  }

  &__price {
    position: absolute;
    top: 15px;
    left: 15px;
    background: $red;
    border-radius: 5px;
    padding: 5px 8px;
    color: #fff;
  }

  &__annex {
    display: flex;
    padding: 5px 10px;
    margin-top: 5px;
    color: #000;
    font-family: $Lato;

    &--part {
      &.-left {
        width: 60%;
      }
      &.-right {
        width: 40%;
        padding-left: 10px;
        border-left: 1px solid #eee;
      }
    }
  }
}
