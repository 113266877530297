.button {
  &__main {
    padding: 10px 30px;
    background: $red;
    border: 1px solid transparent;
    border-radius: 100px;
    color: #fff;
    font-family: $Lato;
    font-size: 12px;
    text-decoration: none;
    line-height: 1.2;
    white-space: nowrap;

    &:hover {
      background: darken($red, 5%);
    }

    &:active,
    &:link,
    &:visited {
      color: #fff;
    }
  }
}
