.chat-label {
  font-family: 'MYRIAD';
  color: grey;
  font-size: 18px;

  &.-active {
    color: $orange;
    font-weight: bold;
  }
}
